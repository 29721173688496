import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const CircleCheck: React.FC<IconStyle> = ({
  width = '25',
  height = '25',
  stroke = '#116F31',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12.5' cy='12.5' r='12.5' fill={fill} />
      <path
        transform='translate(7, 7.5)'
        d='M1 6L4 9L10 1'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CircleCheck
