import * as React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { navigate } from 'gatsby'

import Container from 'components/common/Container'
import TabBar from 'components/common/TabBar'
import CaretLeft from 'components/vectors/CaretLeft'
import Button from 'components/common/Button'
import Image from 'components/custom/Image'

import { ImageImport } from 'lib/utils/typings'
import colors from 'lib/utils/colors'

const GlobalStyle = createGlobalStyle`
 a, div {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
 }

 .gatsby-image-wrapper [data-main-image] {
   opacity: 1;
   transition: none;
 }
`

const ScreenContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageViewContainer = styled(Container)`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 3px 8px 1px ${colors.ShadowLight};
  -webkit-box-shadow: 4px 3px 8px 1px ${colors.ShadowLight};
  min-height: 668px;
  background-color: #343434;
  height: 100%;
`

const PageContainer = styled(Container)`
  height: 100%;
  min-width: 320px;
  max-width: 768px;
  align-items: flex-start;
`

const PageContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
  max-width: 768px;
  width: 100%;
  background-color: transparent;
  z-index: 2;
  justify-content: flex-start;
`

const PageTitleContainer = styled(Container)<{ fixedHeader?: boolean; backgroundColor?: string }>`
  padding: 24px 16px;
  min-height: 69px;
  max-height: 69px;
  min-width: 320px;
  max-width: 768px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    `${props.backgroundColor && props.fixedHeader ? props.backgroundColor : 'transparent'}`};
  position: ${(props) => `${props.fixedHeader ? 'sticky' : 'inherit'}`};
  top: 0;
`

const PageTitle = styled.h3`
  color: ${colors.Brass};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin: 0;
`

const Spacer = styled.div`
  min-width: 20px;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`

const BackgroundImageContainer = styled(Container)`
  height: 100%;
  position: absolute;
  z-index: 1;
`

type Props = {
  backgroundColor?: string
  backgroundImage?: ImageImport
  content?: React.ReactNode
  showTabBar?: boolean
  currentPath?: string
  pageTitle?: string
  showBackButton?: boolean
  fixedHeader?: boolean
}

const MainLayout: React.FC<Props> = ({
  backgroundColor,
  backgroundImage,
  content,
  showTabBar,
  currentPath,
  pageTitle,
  showBackButton,
  fixedHeader,
}) => {
  return (
    <>
      <GlobalStyle />
      <ScreenContainer>
        <PageViewContainer>
          <PageContainer backgroundColor={backgroundColor}>
            {backgroundImage && (
              <BackgroundImageContainer>
                <Image imgSrc={backgroundImage} alt='background' />
              </BackgroundImageContainer>
            )}
            <PageContentContainer>
              {pageTitle && (
                <PageTitleContainer fixedHeader={fixedHeader} backgroundColor={backgroundColor}>
                  {showBackButton ? (
                    <Button type='icon' onClick={() => navigate(-1)}>
                      <CaretLeft />
                    </Button>
                  ) : (
                    <Spacer />
                  )}
                  <PageTitle>{pageTitle.toUpperCase()}</PageTitle>
                  <Spacer />
                </PageTitleContainer>
              )}
              <ContentContainer>{content}</ContentContainer>
              {showTabBar && <TabBar currentPath={currentPath} />}
            </PageContentContainer>
          </PageContainer>
        </PageViewContainer>
      </ScreenContainer>
    </>
  )
}

export default MainLayout
