import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const CaretLeft: React.FC<IconStyle> = ({
  width = '11',
  height = '19',
  stroke = '#B99A44',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='8.79999'
        y='18.4142'
        width='12.4451'
        height='3.11127'
        transform='rotate(-135 8.79999 18.4142)'
        fill={stroke}
      />
      <rect
        x='11'
        y='2.81787'
        width='12.4451'
        height='3.11127'
        transform='rotate(135 11 2.81787)'
        fill={stroke}
      />
    </svg>
  )
}

export default CaretLeft
