import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Cross: React.FC<IconStyle> = ({
  width = '10',
  height = '11',
  stroke = '#B14B44',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='8.15617'
        y1='1.40556'
        x2='1.40561'
        y2='9.84377'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='1'
        y1='-1'
        x2='11.8062'
        y2='-1'
        transform='matrix(0.624695 0.780869 0.780869 -0.624695 1.59692 0)'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default Cross
