import React from 'react'

import { IconStyle } from 'lib/utils/typings'

import BookOpen from './BookOpen'
import Headphones from './Headphones'
import CircleCheck from './CircleCheck'
import CircleCross from './CircleCross'
import Check from './Check'
import Cross from './Cross'

export type IconProp = {
  name?: 'book-open' | 'headphones' | string
  iconStyle?: IconStyle
}

const Icon: React.FC<IconProp> = ({ iconStyle, name }) => {
  switch (name) {
    case 'book-open': {
      return <BookOpen {...iconStyle} />
    }
    case 'headphones': {
      return <Headphones {...iconStyle} />
    }
    case 'circle-check': {
      return <CircleCheck {...iconStyle} />
    }
    case 'circle-cross': {
      return <CircleCross {...iconStyle} />
    }
    case 'check': {
      return <Check {...iconStyle} />
    }
    case 'cross': {
      return <Cross {...iconStyle} />
    }
    default: {
      return <></>
    }
  }
}

export default Icon
