import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image'

type Props = {
  imgSrc: string
  alt: string
  style?: React.CSSProperties
}

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image: React.FC<Props> = ({ imgSrc, alt, style }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            edges {
              node {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      `}
      render={(data) => {
        const imageNode = data.allImageSharp.edges.find(
          (element: { node: { gatsbyImageData: { images: { fallback: { src: string } } } } }) => {
            // Match string after final slash
            return element.node.gatsbyImageData.images.fallback.src.split('/').pop() === imgSrc
          }
        )?.node as ImageDataLike

        const image = getImage(imageNode) as IGatsbyImageData

        return (
          <GatsbyImage
            image={{ ...image, backgroundColor: 'transparent' }}
            alt={alt}
            style={style}
            loading='eager'
          />
        )
      }}
    />
  )
}

export default Image
