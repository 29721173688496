import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Headphones: React.FC<IconStyle> = ({
  width = '34',
  height = '34',
  stroke = 'black',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.31998 25.54H5.31998C4.1875 25.54 3.10141 25.0901 2.30063 24.2894C1.49985 23.4886 1.04999 22.4025 1.04999 21.27V17C1.05264 15.8693 1.50368 14.7857 2.30417 13.9871C3.10466 13.1885 4.18923 12.74 5.31998 12.74H6.31998C6.46002 12.7387 6.59894 12.7651 6.7287 12.8178C6.85846 12.8705 6.9765 12.9484 7.07599 13.0469C7.17548 13.1455 7.25446 13.2628 7.30835 13.392C7.36224 13.5213 7.38999 13.66 7.38998 13.8V24.47C7.38998 24.6105 7.36231 24.7497 7.30853 24.8795C7.25476 25.0093 7.17593 25.1273 7.07657 25.2266C6.97721 25.326 6.85928 25.4048 6.72946 25.4586C6.59964 25.5123 6.46049 25.54 6.31998 25.54V25.54Z'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.73 25.54H27.67C27.3862 25.54 27.114 25.4273 26.9134 25.2266C26.7127 25.0259 26.6 24.7538 26.6 24.47V13.8C26.6 13.66 26.6277 13.5213 26.6816 13.392C26.7355 13.2628 26.8145 13.1455 26.914 13.0469C27.0135 12.9484 27.1315 12.8705 27.2613 12.8178C27.391 12.7651 27.5299 12.7387 27.67 12.74H28.73C29.8607 12.74 30.9453 13.1885 31.7458 13.9871C32.5463 14.7857 32.9973 15.8693 33 17V21.27C33 22.4025 32.5501 23.4886 31.7493 24.2894C30.9485 25.0901 29.8625 25.54 28.73 25.54V25.54Z'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.31998 12.74C5.31998 9.62809 6.55549 6.64349 8.755 4.4421C10.9545 2.24071 13.9381 1.00265 17.05 1V1C18.5912 0.999999 20.1174 1.30373 21.5412 1.89384C22.965 2.48396 24.2585 3.34888 25.3479 4.43917C26.4372 5.52946 27.3011 6.82375 27.89 8.24805C28.4789 9.67236 28.7813 11.1988 28.78 12.74'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.32 30.87H24.52C25.6507 30.8674 26.7342 30.4163 27.5329 29.6158C28.3315 28.8153 28.78 27.7308 28.78 26.6V26.6V25.54'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.13 33H17C16.4351 33 15.8933 32.7756 15.4938 32.3761C15.0944 31.9767 14.87 31.4349 14.87 30.87V30.87C14.87 30.3051 15.0944 29.7633 15.4938 29.3638C15.8933 28.9644 16.4351 28.74 17 28.74H19.13C19.4105 28.7387 19.6886 28.7928 19.9482 28.8992C20.2077 29.0057 20.4437 29.1624 20.6426 29.3603C20.8414 29.5582 20.9992 29.7935 21.1069 30.0525C21.2146 30.3116 21.27 30.5894 21.27 30.87C21.27 31.1505 21.2146 31.4283 21.1069 31.6874C20.9992 31.9465 20.8414 32.1817 20.6426 32.3797C20.4437 32.5776 20.2077 32.7343 19.9482 32.8407C19.6886 32.9472 19.4105 33.0013 19.13 33V33Z'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Headphones
