import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const BookOpen: React.FC<IconStyle> = ({
  width = '34',
  height = '33',
  stroke = 'black',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.0001 31.72V6.95001C17.0001 6.95001 13.5101 3.08001 1.74006 2.95001C1.64222 2.94823 1.54501 2.96588 1.45404 3.00193C1.36306 3.03798 1.28013 3.0917 1.21006 3.16001C1.0757 3.30385 1.00069 3.49318 1.00007 3.69002V26.97C0.997372 27.1619 1.07036 27.3472 1.20324 27.4857C1.33613 27.6242 1.5182 27.7048 1.71006 27.71C13.5101 27.87 17.0001 31.71 17.0001 31.71'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7301 16.15C10.3099 15.3366 7.79666 14.8326 5.25006 14.65'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7301 22.12C10.3087 21.3113 7.79601 20.8075 5.25006 20.62'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.2701 16.15C23.6902 15.3366 26.2035 14.8326 28.7501 14.65'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.2701 22.12C23.6915 21.3113 26.2041 20.8075 28.7501 20.62'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.7501 3.08998C29.8301 2.99998 31.0001 2.93998 32.2601 2.91998C32.4538 2.92113 32.6398 2.99625 32.7801 3.12998C32.9208 3.2705 32.9999 3.46114 33.0001 3.65998V26.94C33.0028 27.1319 32.9298 27.3172 32.7969 27.4557C32.664 27.5941 32.4819 27.6747 32.2901 27.68C20.4901 27.84 17.0001 31.68 17.0001 31.68'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0001 31.72V6.95001C17.0001 6.95001 18.6201 5.15001 23.4001 3.95001'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.7301 10.39V1C26.9338 1.14248 25.1516 1.42669 23.4001 1.85V10.39L26.0701 8.21L28.7301 10.39Z'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default BookOpen
