import colors from 'lib/utils/colors'
import * as React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  text?: string
  children?: React.ReactNode
  onClick?: () => void

  /* style type */
  type?: 'icon'
  primary?: boolean
  secondary?: boolean

  color?: string
  backgroundColor?: string
}

const ButtonContainer = styled.div<{
  color?: string
  backgroundColor?: string

  /* style type */
  primary?: boolean
  secondary?: boolean
  type?: 'icon'
}>`
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  padding: 19px;
  line-height: 20px;
  letter-spacing: 0.03;

  :hover {
    cursor: pointer;
  }

  ${(props) => {
    if (props.color) {
      return css`
        color: ${props.color};
      `
    }
  }}

  ${(props) => {
    if (!props.secondary) {
      if (props.primary || !props.primary) {
        return css`
          background-color: ${props.backgroundColor || colors.Brass};
        `
      }
    }
  }}


  ${(props) => {
    switch (props.type) {
      case 'icon': {
        return css`
          padding: 0;
          background-color: ${props.backgroundColor || 'transparent'};
        `
      }
    }
  }}
`
const Button: React.FC<Props> = ({
  text,
  color,
  primary,
  secondary,
  backgroundColor,
  type,
  children,
  onClick,
}) => {
  return (
    <ButtonContainer
      color={color}
      backgroundColor={backgroundColor}
      primary={primary}
      secondary={secondary}
      type={type}
      onClick={onClick}
    >
      {text}
      {children}
    </ButtonContainer>
  )
}

export default Button
