import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const CircleCross: React.FC<IconStyle> = ({
  width = '25',
  height = '25',
  stroke = '#116F31',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12.5' cy='12.5' r='12.5' fill={fill} />
      <g clipPath='url(#clip0_212_10860)' transform='translate(7, 7.5)'>
        <line
          x1='8.65984'
          y1='0.405564'
          x2='1.90928'
          y2='8.84377'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='round'
        />
        <line
          x1='1'
          y1='-1'
          x2='11.8062'
          y2='-1'
          transform='matrix(0.624695 0.780869 0.780869 -0.624695 2.10059 -1)'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_212_10860'>
          <rect width='11' height='10' fill={stroke} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircleCross
