import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/common/Container'
import Icon from 'components/vectors/Icon'
import Button from './Button'
import AppIconSVG from 'assets/icons/app-icon.svg'

import { IconStyle } from 'lib/utils/typings'

import tabBarData from 'content/tab-bar.json'

const TabBarContainer = styled(Container)<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor || 'white'};
  min-height: 80px;
  max-height: 80px;
  width: 100%;
  padding: 22px 52px 27px;
  position: sticky;
  bottom: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
`

const TabsContainer = styled(Container)`
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
`

const OrbLink = styled(Link)`
  align-self: flex-end;
`

const Orb = styled(Container)`
  display: block;
  border: 1px solid #e4e4e4;
  width: 73px;
  height: 73px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 9px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
`

type TabProps = {
  icon?: string
  iconSrc?: string
  active?: boolean
  iconStyle?: IconStyle
}

const Tab: React.FC<TabProps> = ({ icon = '', active, iconStyle }) => {
  const { iconColor } = tabBarData

  const finalIconStyle = {
    ...iconStyle,
    stroke: active ? iconColor.active : iconColor.inactive,
  }

  if (icon === 'app-icon') {
    return <AppIconSVG />
  }

  return <Icon name={icon} iconStyle={finalIconStyle} />
}

type Props = {
  currentPath?: string
}

const TabBar: React.FC<Props> = ({ currentPath }) => {
  const { backgroundColor, tabs } = tabBarData
  const { mainTab, leftTab, rightTab } = tabs

  return (
    <TabBarContainer backgroundColor={backgroundColor}>
      <TabsContainer>
        <Link to={leftTab.linkTo}>
          <Button type='icon'>
            <Tab {...leftTab} active={currentPath?.startsWith(leftTab.linkTo)} />
          </Button>
        </Link>
        <OrbLink to={mainTab.linkTo}>
          <Button type='icon'>
            <Orb>
              <Tab {...mainTab} />
            </Orb>
          </Button>
        </OrbLink>
        <Link to={rightTab.linkTo}>
          <Button type='icon'>
            <Tab {...rightTab} active={currentPath?.startsWith(rightTab.linkTo)} />
          </Button>
        </Link>
      </TabsContainer>
    </TabBarContainer>
  )
}

export default TabBar
