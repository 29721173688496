import { set, get, del } from 'idb-keyval'
import { User, UserCredentials } from 'models/Auth'

const authKey = 'appUser'

export const handleLogin = async ({ username, password }: UserCredentials) => {
  const now = new Date()

  const result = await fetch(process.env.GATSBY_AUTH_URL || '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  }).then((data) => data.json())

  await set(authKey, {
    username: result.user.username,
    name: result.user.name,
    email: result.user.email,
    // 1 day
    expiry: now.getTime() + 1000 * 60 * 60 * 24,
  })
}

export const isLoggedIn = async () => {
  const now = new Date()

  const user = (await get(authKey)) as unknown as User

  if (!user) {
    return false
  }

  if (now.getTime() > user.expiry) {
    await del(authKey)
    return false
  }

  return !!user.username
}

export const logout = async (callback: () => void) => {
  await set(authKey, {})
  callback()
}
